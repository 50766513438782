/* styles.css */

.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footer__social {
  margin-top: 20px;
  display: flex; /* Display links in a row */
  justify-content: center; /* Center the links horizontally */
}

.social-icon {
  font-size: 30px;
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.social-icon:hover {
  color: #007bff;
}

.footer__contact p {
  margin: 10px 0;
}

.footer__contact a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer__contact a:hover {
  color: #fff;
}
