@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Barlow";
  src: url("../src/assets/fonts/Barlow-Regular.ttf") format("truetype");
  /* Add other font properties if needed, e.g., font-weight, font-style */
}

body {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-family: "Barlow", sans-serif;

  user-select: none;
}
::-webkit-scrollbar {
  display: none;
}

#Main {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  /* height: 95vh; */
  height: 100vh;
}
.swiper-pagination-bullet {
  background-color: red;
}

section {
  height: 100%;
  position: relative;
  scroll-snap-align: end start;
  scroll-snap-stop: always;
  overflow-x: hidden;
  overflow: scroll;
  scroll-behavior: smooth;
}
@media (max-width: 767px) {
  #Main {
    padding-bottom: 3%;
  }
}
